<template>
  <div class="list">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>奶茶管理</el-breadcrumb-item>
      <el-breadcrumb-item>列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="searchQuery"
            clearable
            @clear="getMilkteaList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getMilkteaSearch"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" plain @click="$router.push({path: '/mtAdd'})">添加奶茶</el-button>
        </el-col>
      </el-row>

      <el-table :data="milkteaList" border stripe>
        <el-table-column align="center" type="index"></el-table-column>
        <el-table-column label="商品分类">
          <template slot-scope="scope" >
              <el-select style="width:60%;" @focus="handle(scope.row)" v-model="scope.row.fenlei_id" @change="changeCategory">
                  <el-option v-for="(item, index) in categoryList" :key="index" :label="item.text" :value="item.fenlei_id"></el-option>
              </el-select>
            </template>
        </el-table-column>
        <el-table-column align="center" label="奶茶图片">
          <template slot-scope="scope">
            <img v-if="scope.row.img" class="img" :src="scope.row.img" />
            <span v-else>{{ scope.row.key }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="标题"
          prop="title"
        ></el-table-column>
        <el-table-column
          align="center"
          label="原料"
          prop="yuanliao"
        ></el-table-column>
        <el-table-column
          align="center"
          label="价格"
          prop="money"
        ></el-table-column>
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeMilkteaById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="修改填报信息"
      :visible.sync="editDialogVisible"
      width="66%"
      @close="editDialogClosed"
    >
      <el-form :model="editForm" ref="editFormRef" label-width="90px">
        <el-form-item label="标题">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="原料">
          <el-input v-model="editForm.yuanliao"></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="editForm.money"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editMilkteaInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryList: [],
      milkteaList: [],
      searchQuery: "",
      editDialogVisible: false,
      editForm: [],
    };
  },
  created() {
    this.getMilkteaList();
    this.getCategoryList();
  },
  methods: {
    // 获取所有奶茶数据
    async getMilkteaList() {
      const { data: res } = await this.$http.get("getMilkteaData");
      this.milkteaList = res.list;
    },

    // 获取分类
    async getCategoryList() {
      const { data: res } = await this.$http.get('getCategoryTabs');
      this.categoryList = res.list;
    },

    // 修改奶茶分类
    async changeCategory(e) {
      console.log(e)
      this.editForm.category_id = e
      const { data: res } = await this.$http.get('updateMilkteaData', { params: this.editForm });
      this.getMilkteaList();
      console.log(res);
      this.$message.success("修改成功！");
    },

    // 分类框获得焦点时触发
    handle(obj) {
      console.log(obj);
      this.editForm = obj;
    },

    // 关闭修改对话框
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },

    // 修改
    showEditDialog(obj) {
      console.log(obj);
      this.editForm = obj;
      this.editDialogVisible = true;
    },

    // 删除
    async removeMilkteaById(id) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "是否删除?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const { data: res } = await this.$http.get("delMilkteaData", {
        params: {
          id,
        },
      });
      console.log(res.data);
      this.$message.success("删除成功！");
      this.getMilkteaList();
    },

    // 确认修改
    async editMilkteaInfo() {
      const { data: res } = await this.$http.get("updateMilkteaData", {
        params: this.editForm,
      });
      console.log(res);
      // 关闭对话框
      this.editDialogVisible = false;
      this.$message.success("修改成功！");
      this.getMilkteaList();
    },

    // 搜索
    async getMilkteaSearch() {
      const { data: res } = await this.$http.get("searchMilkteaData", {
        params: {
          title: this.searchQuery,
        },
      });
      this.milkteaList = res.list;
    },
  },
};
</script>
<style lang="less" scoped>
.img {
  width: 120px;
  height: 80px;
}
</style>